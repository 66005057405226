<template>
    <div class="wrapper event-details order-details-page">
        <div class="details-top">
            <div class="left">
                <div class="date">
                    <div class="circle">
                        <font-awesome-icon icon="fa-solid fa-calendar" />
                    </div>
                    <span>{{ date }}</span>
                </div>
                <div class="recommendations">
                    <div class="circle orange">
                        <font-awesome-icon icon="fa-solid fa-arrow-down" />
                    </div>
                    <span>Termék ajánlatok letöltése</span>
                </div>
            </div>
            <div class="right description">
                <span class="title">Esemény leírás</span>
                <p>
                    Phasellus convallis metus ac scelerisque pellentesque. Nulla dictum lacus lacus, eu pharetra nisl vulputate in.
                    Donec porta ex eros, non fermentum lacus pharetra sed. Sed at euismod ipsum. Nam convallis justo consequat sollicitudin pretium.
                    Mauris odio elit
                </p>
            </div>
        </div>

        <div class="content">
            <div class="left">
                <div class="headline">
                    <h1>Előrendelés</h1>
                </div>

                <h2>
                    Alap csomag tartalma
                </h2>
                <div class="description">
                    <p>
                        Tartalma: Curabitur tempus lacus quis leo sollicitudin molestie. Praesent ipsum urna, hendrerit a mi dapibus, congue sagittis..
                        Donec luctus sed arcu et imperdiet. Nullam sit amet faucibus velit, eu condimentum metus. Morbi ac nulla a justo blandit eleifend in nec sapien.
                    </p>
                    <p>
                        <strong>Foglalás tartalmazza</strong>
                        <font-awesome-icon icon="fa-solid fa-circle-check" />
                    </p>
                </div>

                <div class="food-content-list">
                    <div class="package">
                        <food-item @open-details="openModal"
                        :status="item.status"
                        :canChange="item.canChange"
                        :showPrice="item.showPrice"
                        :key="'item-'+key"
                        v-for="(item, key) in items"></food-item>
                    </div>
                </div>
            </div>

            <div class="right">
                <cart :readonly="true"></cart>
            </div>
        </div>

        <vue-final-modal v-model="modalOpened">
            <div class="modal-product">
                <div class="close-btn">
                    <div class="circle">
                        <button @click="closeModal()">
                            <font-awesome-icon icon="fa-solid fa-xmark" />
                        </button>
                    </div>
                </div>
                <div class="image">
                    <img src="../../assets/samples/modal-image.png" alt="">
                </div>
                <div class="content">
                    <div class="name">
                        <strong>“A” Menü</strong>
                    </div>
                    <div class="price">
                        189.000 Ft + 27% ÁFA
                    </div>

                    <div class="description">
                        <p>
                            Quisque ex metus, scelerisque et fringilla nec, varius ac elit. Cras facilisis egestas justo,
                            sed elementum nibh rhoncus non. Vestibulum et feugiat leo, at ultricies massa.
                        </p>
                        <ul>
                            <li>12 db Terméknév</li>
                            <li>24 csomag Terméknév</li>
                            <li>8 üveg Terméknév</li>
                            <li>6 doboz Terméknév</li>
                        </ul>
                    </div>
                </div>
                <div class="bottom">
                    <div class="qtyButtons">
                        <button @click="changeQty('minus')">
                            <font-awesome-icon icon="fa-solid fa-minus" />
                        </button>
                        <div class="num">{{ quantity }}</div>
                        <button @click="changeQty('plus')">
                            <font-awesome-icon icon="fa-solid fa-plus" />
                        </button>
                    </div>
                    <div class="addToCart">
                        <button @click="closeModal">Hozzáadás a kosárhoz <span class="arrow"></span></button>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import Cart from '../../components/skybox/cart/cart.vue'
import { VueFinalModal } from 'vue-final-modal'
import FoodItem from '../../components/skybox/food-item.vue'

export default {
    name: "order-details",
    components: { Cart, VueFinalModal, FoodItem },
    data() {
        return {
            quantity: 1,
            title: "2022-es darts Premier League verseny háromsoros cím",
            date: "2022. március 02.",
            description: "Phasellus convallis metus ac scelerisque pellentesque. Nulla dictum lacus lacus, eu pharetra nisl vulputate in. Donec porta ex eros, non fermentum lacus pharetra sed. Sed at euismod ipsum. Nam convallis justo consequat sollicitudin pretium. Mauris odio elit, tincidunt non pretium sed, mollis eu massa. Etiam quis faucibus neque, in sodales felis. Praesent malesuada lacus vitae nulla convallis ultricies.",
            modalOpened: false,
            items: [{
                    canChange: true,
                    status: 'ordered',
                    showPrice: false
                }, {
                    canChange: false,
                    status: 'ordered',
                    showPrice: false
                }]
        }
    },
    mounted() {
        this.$store.commit('setSkyboxTitle', 'Összegzés')
        this.$store.commit('setSkyboxText', '')
        this.$store.commit('setSkyboxLink', 'history-back')
        this.$store.commit('setSkyboxLinkText', 'Termékek kiválasztása')
    },
    methods: {
        closeModal() {

        },
        openModal() {
            
        }
    }
}
</script>